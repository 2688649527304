import React, { useState } from 'react';
import { Col, Row, Button } from 'reactstrap';
import classnames from 'classnames';
import {
    DragDropContext,
    Draggable,
    DraggableLocation,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    DropResult
} from 'react-beautiful-dnd';
import { ITemplate } from '../../interfaces/business';

/* helper function to reorder the result */
const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source: ITemplate[], destination: ITemplate[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

interface IProps {
    allItems: ITemplate[],
    selectedItems: ITemplate[],
    className?: string,
    children?: never;
    onItemsChange: (items: ITemplate[]) => void;
}

export const TemplateDND: React.FC<IProps> = (props: IProps) => {

    const [selected, setSelected] = useState<ITemplate[]>([...props.selectedItems]);
    const [available, setAvailable] = useState<ITemplate[]>([...props.allItems.filter(avItem => !props.selectedItems.find(selItem => selItem.templateNumber === avItem.templateNumber))]);
    const [showAvailableTemplates, setShowAvailableTemplates] = useState(false);

    const getList = (id: string) => id === 'selected' ? selected : available;
    const onShowAvailableTemplateToggled = () => setShowAvailableTemplates(!showAvailableTemplates);

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        // moved inside same list
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.droppableId === 'selected') {
                setSelected(items);
                props.onItemsChange(items);
            } else {
                setAvailable(items);
            }
        } else {
            // moved from one list to another
            const updatedState = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );
            setSelected(updatedState.selected);
            setAvailable(updatedState.available);
            props.onItemsChange(updatedState.selected);
        }
    };

    const getDraggableItems = (items: ITemplate[]) => items.map((item, index) =>
        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot,) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                     className={classnames('dnd-item', { 'dnd-item-dragging': snapshot.isDragging })}>
                    <span>{item.templateNumber}</span>
                </div>
            )}
        </Draggable>
    );

    return <Row className={props.className}>
        <DragDropContext onDragEnd={onDragEnd}>
            <Col sm='12' className='mt-1'>
                <p>Templates (drag and drop)</p>
                <Droppable droppableId='selected' direction='horizontal'>
                    {(provided, snapshot) =>
                        <div ref={provided.innerRef}
                             className={classnames('dnd-list', { 'dnd-list-destination': snapshot.isDraggingOver })}>
                            {getDraggableItems(selected)}
                            {provided.placeholder}
                        </div>
                    }
                </Droppable>
                <Button className='btn-icon-action btn-sm my-2' onClick={onShowAvailableTemplateToggled}
                        id='btnAdminToggleFiltersVisibility'>
                    {!showAvailableTemplates && <span>
                        <i className='icon icon-add-primary icon--left'/>More templates</span>}
                    {showAvailableTemplates &&
                        <span><i className='icon icon-chevron-up-primary icon--left'/>Available templates (drag and drop)</span>}
                </Button>
            </Col>

            {showAvailableTemplates &&
                <Col sm='12'>
                    <Droppable droppableId='available' direction='horizontal'>
                        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) =>
                            <div ref={provided.innerRef}
                                 className={classnames('dnd-list', { 'dnd-list-destination': snapshot.isDraggingOver })}>
                                {getDraggableItems(available)}
                                {provided.placeholder}
                            </div>
                        }
                    </Droppable>
                </Col>}

        </DragDropContext>
    </Row>;
}

export default TemplateDND;
