import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import deckApi from '../../api/deck-api';
import { IDeck, ITemplate, ITemplateFile } from '../../interfaces/business';
import { templateActions } from '../../store';
import { IRootState } from '../../store/store';
import { getUniqueByProp } from '../../utils/sortHelper';
import { groupByCombi } from '../../utils/templateFileUtils';
import { ICheckboxChangeEvent } from '../atoms/Checkbox';
import { Checkbox, CheckboxGroup, RadioGroup, TextInput } from '../atoms/index';
import { IRadioOption } from '../atoms/RadioGroup';
import { TemplateCoverage, TemplateDND } from '../business';

interface IProps {
    deck: IDeck,
    templates: ITemplate[],
    templateFiles: ITemplateFile[],
    isOpen: boolean,
    onClose: () => void,
    children?: never;
    reloadDecks: (payload: void) => void,
}

interface IState extends IDeck {
    invalidFields: any;
    requestInProgress?: boolean;
    errorMessage?: string;
}

export class DeckModal extends React.PureComponent<IProps, IState> {

    state: IState = {
        ...this.props.deck,
        description: this.props.deck.description || '',
        invalidFields: {},
        requiredBCs: this.props.deck.requiredBCs || [],
        requiredRMLocations: this.props.deck.requiredRMLocations || [],
    };

    onExternalIdChange = (externalId: string) => this.setState({ externalId });
    onNameChange = (name: string) => this.setState({ name });
    onDescriptionChange = (description: string) => this.setState({ description });
    onDefaultLanguageChange = (defaultLanguage: string) => this.setState({ defaultLanguage });

    getAvailableBCs = (templates: ITemplate[]) => getUniqueByProp(templates.map(t => t.templateFiles).flat(), 'bookingCenter').map(file => file.bookingCenter);
    getAvailableRMLs = (templates: ITemplate[]) => getUniqueByProp(templates.map(t => t.templateFiles).flat(), 'rmLocation')
        .map(file => file.rmLocation);

    onTemplateChange = (templates: ITemplate[]) => {
        this.setState({ templates });
        // cleanup default language on template change
        const defaultLanguages = this.getDefaultLanguagesForTemplates(templates);
        if (this.state.defaultLanguage && !defaultLanguages.includes(this.state.defaultLanguage)) {
            this.setState({ defaultLanguage: undefined });
        } else if (defaultLanguages.length === 1) {
            this.setState({ defaultLanguage: defaultLanguages[0] });
        }
        // cleanup required BC and RM location selection
        const availableBCs = this.getAvailableBCs(templates);
        this.setState({ requiredBCs: this.state.requiredBCs.filter(selectedBC => availableBCs.includes(selectedBC)) })
        const availableRMLs = this.getAvailableRMLs(templates);
        this.setState({ requiredRMLocations: this.state.requiredRMLocations.filter(selectedRML => availableRMLs.includes(selectedRML)) })
    };

    getDefaultLanguagesForTemplates = (templates: ITemplate[]) => {
        const templateFiles: ITemplateFile[] = templates.map(template => template.templateFiles).flat();
        const templateLanguages = getUniqueByProp(templateFiles, 'language').map(t => t.language);

        return templateLanguages.filter(lang => {
            const filesWithLanguage = templateFiles.filter(tf => tf.language === lang);
            const coveredBCandRMLs = groupByCombi(filesWithLanguage, ['bookingCenter', 'rmLocation']).filter(group => group.length === templates.length);
            // keep languages, where deck is available at least for one booking center
            const requiredNrOfGroups = this.state.requiredBCs.length * (this.state.requiredRMLocations.length || 1);
            return coveredBCandRMLs.length >= requiredNrOfGroups;
        });
    };

    onSubmit = () => {
        if (!this.validateForm()) {
            return;
        }
        const deck: IDeck = {
            id: this.props.deck.id,
            defaultLanguage: this.state.defaultLanguage,
            description: this.state.description,
            externalId: this.state.externalId,
            name: this.state.name,
            templates: this.state.templates,
            requiredBCs: this.state.requiredBCs,
            requiredRMLocations: this.state.requiredRMLocations,
        };

        this.setState({ requestInProgress: true });
        deckApi.saveDeck(deck).then(() => {
            this.props.reloadDecks();
            this.props.onClose();
        }, (e) => {
            this.setState({ errorMessage: e.response.data.message, requestInProgress: false });
        });
    };

    validateForm: () => boolean = () => {
        const invalidFields: any = {
            name: !this.state.name,
            externalId: !this.state.externalId,
            defaultLanguage: !this.state.defaultLanguage,
            templates: !this.state.templates.length,
            requiredBCs: !this.state.requiredBCs.length,
            requiredRMLocations: !this.state.requiredRMLocations.length,
        };
        this.setState({ invalidFields });
        return Object.values(invalidFields).indexOf(true) === -1;
    };

    onBcChange = (bc: string, checked: boolean) => {
        if (checked && !this.state.requiredBCs.includes(bc)) {
            this.setState({ requiredBCs: this.state.requiredBCs.concat([bc]) })
        } else if (!checked) {
            this.setState({ requiredBCs: this.state.requiredBCs.filter(checkedBC => checkedBC !== bc) });
        }
    };

    onRmLocationChange = (rmLocation: string, checked: boolean) => {
        if (checked && !this.state.requiredRMLocations.includes(rmLocation)) {
            this.setState({ requiredRMLocations: this.state.requiredRMLocations.concat([rmLocation]) })
        } else if (!checked) {
            this.setState({ requiredRMLocations: this.state.requiredRMLocations.filter(checkedRML => checkedRML !== rmLocation) });
        }
    };

    render() {
        const defaultLanguageOptions: IRadioOption[] = this.getDefaultLanguagesForTemplates(this.state.templates)
            .map((lang: string) => ({ value: lang, label: lang.toLowerCase() }));

        const showTemplatesValidationError = !this.state.templates.length && this.state.invalidFields.templates;
        const availableBCs: string[] = this.getAvailableBCs(this.state.templates);
        const availableRMLs: string[] = this.getAvailableRMLs(this.state.templates);
        const requiredBcError = !this.state.requiredBCs.length && this.state.invalidFields.requiredBCs ? 'Specify at least one booking center' : '';
        const requiredRmlError = !this.state.requiredRMLocations.length && this.state.invalidFields.requiredRMLocations ? 'Specify at least one RM location' : '';

        return <Modal isOpen={this.props.isOpen} id='modalDeck'>
            <ModalHeader toggle={this.props.onClose}/>
            <ModalBody>
                <h3>{this.props.deck.id ? 'Edit contract deck' : 'Create contract deck'}</h3>
                <Row>
                    <Col sm='12'><TextInput id='iptDeckName'
                                            label='Name'
                                            placeholder='Enter name'
                                            value={this.state.name}
                                            onChange={this.onNameChange}
                                            required={true}
                                            showValidation={this.state.invalidFields.name}/></Col>
                    <Col sm='4'><TextInput id='iptDeckExternalId'
                                           label='External ID'
                                           placeholder='Enter external ID'
                                           value={this.state.externalId}
                                           onChange={this.onExternalIdChange}
                                           required={true}
                                           showValidation={this.state.invalidFields.externalId}/></Col>
                    <Col sm='8'><TextInput id='iptDeckDescription'
                                           label='Description'
                                           placeholder='Enter description'
                                           value={this.state.description}
                                           onChange={this.onDescriptionChange}
                                           showValidation={this.state.invalidFields.description}/></Col>
                </Row>
                <TemplateDND allItems={this.props.templates}
                             selectedItems={this.state.templates}
                             onItemsChange={this.onTemplateChange}
                             className='mb-2'/>
                {showTemplatesValidationError && <p className='error-message'>Specify at least one template.</p>}
                <Row>
                    <Col sm='12'>
                        <CheckboxGroup name='requiredBC' label='Required booking centers'
                                       invalidMessage={requiredBcError} required={!!requiredBcError}>
                            {availableBCs.length > 0 ? availableBCs.map(bc => {
                                const onBcChange = (e: ICheckboxChangeEvent) => this.onBcChange(bc, e.checked);
                                return <Checkbox id={`requiredBC-${bc}`}
                                                 label={bc.toUpperCase()}
                                                 value={this.state.requiredBCs.includes(bc)}
                                                 key={bc}
                                                 inline={true}
                                                 onChange={onBcChange}/>;
                            }): <p>(empty)</p>}
                        </CheckboxGroup>

                        <CheckboxGroup name='requiredRMLocation' label='Required RM locations'
                                       invalidMessage={requiredRmlError} required={!!requiredRmlError}>
                            {availableRMLs.length > 0 ? availableRMLs.map(rml => {
                                const onRmlChange = (e: ICheckboxChangeEvent) => this.onRmLocationChange(rml, e.checked);
                                return <Checkbox id={`requiredRML-${rml}`}
                                                 label={rml.toUpperCase() === 'WO'? 'World': rml.toUpperCase()}
                                                 value={this.state.requiredRMLocations.includes(rml)}
                                                 key={rml}
                                                 inline={true}
                                                 onChange={onRmlChange}/>;
                            }) : <p>(empty)</p>}
                        </CheckboxGroup>

                        <RadioGroup name='radioDefaultLanguage'
                                    label='Default language'
                                    showValidation={this.state.invalidFields.defaultLanguage}
                                    currentValue={this.state.defaultLanguage}
                                    options={defaultLanguageOptions}
                                    required={true}
                                    inline={true}
                                    onChange={this.onDefaultLanguageChange}/>

                        {this.state.templates.length > 0 && <TemplateCoverage templates={this.state.templates}
                                                                              defaultLanguage={this.state.defaultLanguage}
                                                                              requiredBCs={this.state.requiredBCs}
                                                                              requiredRMLs={this.state.requiredRMLocations}/>}
                    </Col>
                </Row>
                {this.state.errorMessage && <p className='error-message'>{this.state.errorMessage}</p>}
                <div className='btn-row mt-2'>
                    <Button color='primary' id='btnDeckSubmit' onClick={this.onSubmit}>
                        {this.props.deck.id ? 'Save' : 'Create'}
                        {this.state.requestInProgress && <i className='icon icon--right icon-loading-white'/>}
                    </Button>
                    <Button color='secondary' onClick={this.props.onClose} id='btnDeckCancel'>Cancel</Button>
                </div>
            </ModalBody>
        </Modal>;
    };
}

const mapStateToProps = (state: IRootState) => ({
    templates: state.template.templates,
    templateFiles: state.template.templateFiles,
});

const actions = {
    reloadDecks: templateActions.loadDecks.request,
};

export default connect(
    mapStateToProps,
    actions,
)(DeckModal);
