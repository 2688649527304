import axios, { AxiosInstance } from 'axios';
import { CON_BACKEND_URL } from '../config/constants';

export class TemplateApi {
    readonly axios: AxiosInstance;
    readonly templatePath: string;
    readonly templateFilePath: string;

    constructor(api: AxiosInstance, url: string) {
        this.axios = api;
        this.templatePath = `${url}/template`;
        this.templateFilePath = `${url}/template-file`;
    }

    async uploadTemplateFiles(files: File[]) {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file, file.name);
        }
        return this.axios.post(this.templateFilePath, formData, { headers: { 'content-type': 'multipart/form-data' } }).then(response => response.data);
    }

    async getTemplateList() {
        return this.axios.get(this.templatePath).then(response => response.data);
    }

    async getTemplateFileList() {
        return this.axios.get(this.templateFilePath).then(response => response.data);
    }

    async deleteTemplateFile(id: number) {
        return this.axios.delete(`${this.templateFilePath}/${id}`).then(response => response.data);
    }

    // todo change to saveFileFromResponse
    async getTemplateFileById(id: number) {
        return this.axios.get(`${this.templateFilePath}/${id}/download`, { responseType: 'blob' }).then(response => response.data);
    }

}

const instance = new TemplateApi(axios, CON_BACKEND_URL);
export default instance;
