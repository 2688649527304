import { IDeck, IMap, ITemplate, ITemplateFile } from '../interfaces/business';

const flatten: (arrays: any[][]) => any[] = (arrays) => [].concat.apply([], arrays);

export const groupBy: (data: any[], prop: string) => any[][] = (data, prop) => {
    if (!data || data.length === 0) {
        return [];
    }
    const map: any = {};
    data.forEach(item => {
        const entry = map[item[prop]];
        if (entry) {
            entry.push(item);
        } else {
            map[item[prop]] = [item];
        }
    });
    return Object.values(map);
};

export const groupByCombi = <T>(data: T[], props: (keyof T)[]) => {
    if (!data || data.length === 0) {
        return [];
    }
    const map: IMap<T[]> = {};
    data.forEach(item => {
        const compositeKey = props.map(prop => item[prop]).join('-');
        const entry = map[compositeKey];
        if (entry) {
            entry.push(item);
        } else {
            map[compositeKey] = [item];
        }
    });
    return Object.values(map);
};

export const getTemplateConsistencyText: (templates: ITemplate[], requiredBCs: string[], requiredRMLs: string[], defaultLanguage: string) => string = (templates, requiredBCs, requiredRMLs, defaultLanguage) => {
    const fileArrays: ITemplateFile[][] = templates.map(t => t.templateFiles);
    const files = flatten(fileArrays);
    const filesForDefaultLanguage = files.filter(f => f.language === defaultLanguage);
    if (requiredBCs.length && requiredRMLs.length) {
        const filesWithRML =  filesForDefaultLanguage.filter(f => f.rmLocation !== 'WO');
        const bcRmlGroups: ITemplateFile[][] = groupByCombi<ITemplateFile>(filesWithRML, ['bookingCenter', 'rmLocation']);
        const consistentGroups = bcRmlGroups.filter(group => group.length === templates.length).map(group => `booking center ${group[0].bookingCenter} + RM location ${group[0].rmLocation}`);
        return consistentGroups.length? `Deck is available in default language ${defaultLanguage.toUpperCase()} in: ${consistentGroups.join(', ')}.`: 'Deck is not available for the required BC and RM location. Some templates are missing.';
    } else if (requiredBCs.length) {
        const filesWithRmlWorld =  filesForDefaultLanguage.filter(f => f.rmLocation === 'WO');
        const bcGroups = groupBy(filesWithRmlWorld, 'bookingCenter');
        const consistentBC = bcGroups.filter(group => group.length === templates.length).map(fullGroup => fullGroup[0].bookingCenter);
        return `Deck is available in default language ${defaultLanguage.toUpperCase()} in booking centers: ${consistentBC.join(', ')}.`;
    }
    return 'Please specify at least one required RM location.';
};

export const getDefaultDecksRequiringFile: (templateFile: ITemplateFile, decks: IDeck[]) => IDeck[] = (templateFile, decks = []) =>
    decks.filter(deck => deck.defaultLanguage === templateFile.language
        && deck.requiredBCs.includes(templateFile.bookingCenter)
        && deck.templates.find(template => template.templateNumber === templateFile.templateNumber));

export const getOptionalDecksRequiringFile: (templateFile: ITemplateFile, decks: IDeck[]) => IDeck[] = (templateFile, decks = []) =>
    decks.filter(deck => deck.defaultLanguage !== templateFile.language
        && deck.requiredBCs.includes(templateFile.bookingCenter)
        && deck.templates.find(template => template.templateNumber === templateFile.templateNumber));


