import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { saveAsPdf } from '../../utils/fileUtils';
import { ITemplateFile } from '../../interfaces/business';
import { IRootState } from '../../store/store';
import { SortableFileTable } from '../atoms';
import templateApi from '../../api/template-api';
import TemplateImporter from './TemplateImporter';

interface IProps {
    templateFiles: ITemplateFile[];
}

export const Templates: React.FC<IProps> = (props: IProps) => {

    const onDownloadFile = (file: ITemplateFile) => templateApi.getTemplateFileById(file.id).then((data: any) => saveAsPdf(data, file.filename));

    return <Container>
        <Row>
            <Col sm='12'>
                <h2 className='admin-header pt-5 pb-2'>Upload and view templates</h2>
            </Col>
        </Row>
        <TemplateImporter/>
        <Row>
            <Col sm={12}>
                <SortableFileTable files={props.templateFiles}
                                   onFileClick={onDownloadFile}/>
            </Col>
        </Row>
    </Container>;
};

const mapStateToProps = (state: IRootState) => ({
    templateFiles: state.template.templateFiles,
});

export default connect(
    mapStateToProps,
    null,
)(Templates);
